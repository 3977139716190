module directives {
    export module logistics {
        interface IVesselScheduleScope extends ng.IScope {
            ngModel: string;
            ngRequired: boolean;
            openVesselSchedule(): void;
            countryId: number;
            isInbound: boolean;
            searchDate: moment.Moment;
            locationId?: number;
            terminalId?: number;
            selected({ selectedItem }): void;
            ngDisabled: boolean;
        }

        export class vesselScheduleDirective implements ng.IDirective {
            public restrict: 'E';
            public template = `
            <form name="inputForm">
                <p class="input-group input-group-sm has-feedback" ng-class="{'has-error': inputForm[name].$invalid}">
                    <input type="text" name="{{name}}" class="form-control" ng-model="ngModel" ng-required="ngRequired" ng-disabled="ngDisabled" />
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" ng-click="openVesselSchedule()" ng-disabled="ngDisabled"><i class="fa fa-ship"></i></button>
                    </span>
                </p>
            </form>
            `;
            public scope = {
                ngModel: "=",
                ngRequired: "=",
                countryId: "=",
                isInbound: "=",
                searchDate: "=",
                locationId: "=?",
                terminalId: "=?",
                selected: "&",
                ngDisabled: "="
            }

            constructor(private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService) {
            }

            link = ($scope: IVesselScheduleScope, $element: ng.IAugmentedJQuery) => {
                $scope.openVesselSchedule = () => {
                    this.$uibModal.open({
                        animation: true,
                        size: "lg",
                        template: `
                        <div class="modal-header">
                            <h3 class="modal-title" id="modal-title">Vessel Sailing Schedule</h3>
                        </div>
                        <div class="modal-body" id="modal-body">
                            <gts-message-display messages="Messages"></gts-message-display>
                            <div class="col-md-12"><input type="checkbox" ng-model="vesselScheduleCtrl.showSailingSchedules" ng-click="vesselScheduleCtrl.showSailingSchedules_click()"/>&nbsp;Show only records with sailing schedules.</div><br/><br/>
                            <div class="col-md-12" style="padding-left:0px;padding-right:0px;">
                                <div bs-loading-overlay bs-loading-overlay-reference-id="vessel.thing">
                                    <div ui-grid="vesselScheduleCtrl.gvwGrid" ui-grid-pagination ui-grid-resize-columns ui-grid-selection class="gridFullScreen" ng-if="vesselScheduleCtrl.showGrid">
                                        <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="!vesselScheduleCtrl.gvwGrid.data || vesselScheduleCtrl.IsLoading">
                                            <div class="msg" ng-show="!vesselScheduleCtrl.gvwConList.data && !vesselScheduleCtrl.IsLoading">
                                                <span>No Rows</span>
                                            </div>
                                            <div class="loadingGrid" ng-show="vesselScheduleCtrl.IsLoading">
                                                <i class="fa fa-spinner fa-spin"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary" type="button" ng-click="vesselScheduleCtrl.ok()">Ok</button>
                            <button class="btn btn-info" type="button" ng-click="vesselScheduleCtrl.cancel()">Close</button>
                        </div>`,
                        controller: class containerSizeCtrl {
                            showGrid: boolean = false;
                            IsLoading: boolean = false;
                            pageNumber: number = 1;
                            pageSize: number = 25;
                            apiGrid: uiGrid.IGridApi;
                            showSailingSchedules: boolean = false;

                            constructor(private $scope: ng.IScope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                                private vesselScheduleService: interfaces.logistics.IVesselScheduleService, 
                                private generalService: interfaces.applicationcore.IGeneralService, 
                                private $q: ng.IQService,
                                private $timeout: ng.ITimeoutService,
                                private countryId: number,
                                private isInbound: boolean,
                                private searchDate: moment.Moment,
                                private locationId: number,
                                private terminalId: number,
                                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService) {
    
                                this.showGrid = true;
                            }

                            gvwGrid: uiGrid.IGridOptions = {
                                data: [],
                                enableFiltering: true,
                                useExternalFiltering: true,
                                enableCellEdit: false,
                                useExternalSorting: true,
                                multiSelect: false,
                                enableColumnResizing: true,
                                enableRowSelection: true,
                                enableFullRowSelection: true,
                                paginationPageSizes: [25, 50, 75, 100],
                                paginationPageSize: 25,
                                useExternalPagination: true,
                                enableRowHeaderSelection: false,
                                enableHorizontalScrollbar: 2,
                                enableVerticalScrollbar: 1,
                                onRegisterApi: (gridApi) => {
                                    this.apiGrid = gridApi;
                                    gridApi.core.on.filterChanged(this.$scope, () => { this.loadVesselName() });
                                    gridApi.core.on.sortChanged(this.$scope, () => { this.loadVesselName() });
                                    gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { 
                                        this.pageNumber = newPage;
                                        this.pageSize = pageSize;
                                        this.loadVesselName()
                                    });

                                    this.$timeout(() => {
                                        this.loadVesselName();
                                    });
                                },
                                columnDefs: [{
                                    name: "VesselId",
                                    displayName: "VesselId",
                                    field: "VesselId",
                                    visible: false,
                                    enableFiltering: false
                                },
                                {
                                    name: "VesselName",
                                    displayName: "Vessel Name",
                                    field: "VesselName",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`
                                },
                                {
                                    name: "VesselCallSign",
                                    displayName: "Call Sign",
                                    field: "VesselCallSign",
                                    width: 100,
                                    filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                                },
                                {
                                    name: "CustomsOperatorCode",
                                    displayName: "Operator Code",
                                    field: "CustomsOperatorCode",
                                    width: 100,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`
                                },
                                {
                                    name: "CustomsOperatorName",
                                    displayName: "Operator Name",
                                    field: "CustomsOperatorName",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`
                                },
                                {
                                    name: "VoyageNumber",
                                    displayName: "Voyage Number",
                                    field: "VoyageNumber",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`
                                    },
                                {
                                    name: "DateOfArrival",
                                    displayName: "ETA",
                                    field: "DateOfArrival",
                                    width: 100,
                                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                                    visible: this.isInbound,
                                    filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                                    </div>`,
                                },
                                {
                                    name: "DateOfDeparture",
                                    displayName: "ETD",
                                    field: "DateOfDeparture",
                                    width: 100,
                                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                                    visible: !this.isInbound,
                                    filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                                    </div>`,
                                }
                                , {
                                    name: "StackOpening",
                                    displayName: "Stack Opening",
                                    field: "StackOpening",
                                    width: 125,
                                    visible: !this.isInbound,                                    
                                    cellFilter: 'momentDateFilter:"YYYY/MM/DD HH:mm:SS"',
                                    filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLA"></gts-date>
                                    </div>`,
                                }  , {
                                    name: "StackClosing",
                                    displayName: "Stack Closing",
                                    field: "StackClosing",
                                    width: 125,
                                    visible: !this.isInbound,                                    
                                    cellFilter: 'momentDateFilter:"YYYY/MM/DD HH:mm:SS"',
                                    filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLA"></gts-date>
                                    </div>`,
                                } 
                                ,
                                {
                                    name: "StackLocation",
                                    displayName: "Stack Location",
                                    field: "StackLocation",
                                    visible: !this.isInbound,
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`
                                },                                                                 
                                , {
                                        name: "DateOfBerthing",
                                        displayName: "Berthing",
                                        field: "DateOfBerthing",
                                        width: 125,
                                        visible: this.isInbound,
                                        cellFilter: 'momentDateFilter:"YYYY/MM/DD HH:mm:SS"',
                                        filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLA"></gts-date>
                                        </div>`,
                                    },
                                    {
                                        name: "BerthingLocation",
                                        displayName: "Berthing Location",
                                        field: "BerthingLocation",
                                        visible: this.isInbound,
                                        width: 150,
                                        filterHeaderTemplate: `
                                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <div class="input-group-sm">
                                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                    </div>
                                                </div>`
                                    },                                    
                                {
                                    name: "Service",
                                    displayName: "Service",
                                    field: "Service",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`
                                },
                                {
                                    name: "Comments",
                                    displayName: "Comments",
                                    field: "Comments",
                                    width: 150,
                                    enableSorting: false,
                                    enableFiltering: false,
                                },                                
                                ]
                            };

                            private showSailingSchedules_click() {
                                this.showSailingSchedules = !this.showSailingSchedules;
                                this.loadVesselName();
                            }

                            //Load Vessel
                            public loadVesselName() {
                                if (this.countryId) {
                                    
                                    var searchObject: interfaces.applicationcore.ISearchObject = {
                                        filters: [],
                                        sorts: []
                                    }

                                    if (this.apiGrid) {
                                        var grid = this.apiGrid.grid;
                    
                                        angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                            if (n.filters[0].term) {
                                                searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                            }
                    
                                            if (n.sort.direction) {
                                                searchObject.sorts.push({
                                                    Name: n.name,
                                                    SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                                    SortOrder: n.sort.priority
                                                });
                                            }
                    
                                        });
                                    }

                                    if (!this.searchDate)
                                        this.searchDate = moment();

                                    var vesselLookupSearch: interfaces.logistics.IVesselLookupSearch = {
                                        IsInbound: this.isInbound,
                                        CountryId: this.countryId,
                                        OnlyShowSailingSchedules: this.showSailingSchedules,
                                        SearchDate: this.searchDate,
                                        LocationId: this.locationId,
                                        TerminalId: this.terminalId,
                                        Search: searchObject,
                                        NumberRecords: this.pageSize,
                                        PageNumber: this.pageNumber
                                    };

                                    this.IsLoading = true;
                                    this.vesselScheduleService.vesselLookup().save(vesselLookupSearch, (data) => {
                                        this.gvwGrid.data = data;
                                        if(data && data.length > 0) {
                                            this.gvwGrid.totalItems = data[0].NumRecs;
                                        } else {
                                            this.gvwGrid.totalItems = 0;
                                        }

                                        this.IsLoading = false;
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this.$scope);
                                        this.IsLoading = false;
                                    });
                                } else {

                                }
                            }

                            cancel() {
                                this.$uibModalInstance.dismiss();
                            }
    
                            ok() {
                                if(this.apiGrid.selection.getSelectedRows().length > 0) {
                                    var selectedRow = this.apiGrid.selection.getSelectedRows()[0];

                                    this.$uibModalInstance.close(selectedRow);
                                }
                            }
                            
                        },
                        controllerAs: "vesselScheduleCtrl",
                        resolve: {
                            countryId: () => {
                                return $scope.countryId;
                            },
                            isInbound: () => {
                                return $scope.isInbound;
                            },
                            searchDate: () => {
                                return $scope.searchDate;
                            },
                            locationId: () => {
                                return $scope.locationId;
                            },
                            terminalId: () =>{
                                return $scope.terminalId;
                            }
                        }
                    }).result.then(
                        (result: interfaces.logistics.IVesselLookup) => {
                            if(result) {
                                $scope.selected({selectedItem: result});
                            }
                        }
                    );
                }
            }

            

            
            
            static factory(): ng.IDirectiveFactory {
                const directive = ($timeout, $uibModal) => new vesselScheduleDirective($timeout, $uibModal);
                directive.$inject = ['$timeout',"$uibModal"];

                return directive;
            }
        }

        angular.module("app").directive("gtsVesselSchedule", vesselScheduleDirective.factory());
    }
}